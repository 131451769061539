<template>
    <div class="container">
        <loading-state
            v-if="loading">
        </loading-state>

        <!-- List of missing parts (selectable) -->
        <template  v-if="stockItems.length > 0 && device.status_id === '12'">
            <label class="text-normal mb-1">Which parts are you missing?</label>
            <p class="text-muted text-sm">
                <i class="fal fa-info-circle"></i> <strong>Only</strong> select the parts you're missing
            </p>
            <ul class="list-group selection-list mb-4">
                <li class="list-group-item" v-for="(stockItem, index) in stockItems" :key="index">
                    <label class="custom-control custom-checkbox mb-0">
                        <input type="checkbox" class="custom-control-input"
                               :id="stockItem.id"
                               v-model="stockItem.checked" @change="partRequired(stockItem)">
                        <label class="custom-control-label mb-0" :for="stockItem.id">{{stockItem.title}}</label>
                    </label>
                </li>
            </ul>
        </template>

        <!-- List of missing parts -->
        <template  v-if="stockItems.length > 0 && device.status_id !== '12'">
            <label class="text-normal">These parts will be supplied, based on the problems selected:</label>
            <div class="card card-body pb-0">
                <dl class="mb-0">
                    <dt>Parts</dt>
                    <dl v-for="(stockItem, index) in stockItems" :key="index">
                        {{stockItem.title}}
                    </dl>
                </dl>
            </div>
        </template>

        <!-- Info banner -->
        <div class="alert alert-info">
            <animated-info></animated-info>
            <div class="alert-content">
                <p>
                    If more parts are required, go back and update device problems.
                </p>
                <p>
                    If you've selected all relevant problems, but the parts are still not showing, contact your SSM to request an update.
                </p>
            </div>
        </div>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': additionalPartsSelected}"
                :nextLabel="`Next`"
                :nextRoute="`revisit.update-address`"
                :prevRoute="`revisit.device-issues`">
        </fixed-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "RepairParts",

        data() {
            return {
                loading: true,
                stockItems: []
            }
        },

        mounted() {
            this.loadParts();
        },

        computed: {
            ...mapGetters([
                'job',
                'device'
            ]),

            additionalPartsSelected() {
                if (this.device.status_id !== '12') {
                    return true;
                }

                let stockItemSelected = false;
                this.stockItems.forEach(stockItem => {
                    if (!stockItem.checked) {
                        return;
                    }

                    stockItemSelected = true;
                });

                return stockItemSelected;
            }
        },

        methods: {
            loadParts() {
                this.loading = true;
                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                    .then(res => {
                        this.loading = false;
                        this.stockItems = res.data.data.stock_items;
                    })
            },

            partRequired(stockItem) {
                if (stockItem.checked) {
                    this.$http.post(`/api/v4/additional-parts-required`, {
                        'technician_job_id': this.job.id,
                        'stock_item_id': stockItem.id
                    });

                    return;
                }

                this.$http.post(`/api/v4/additional-parts-required/delete`,{
                    'technician_job_id': this.job.id,
                    'stock_item_id': stockItem.id
                });
            }
        }
    }
</script>

<style scoped>

</style>
